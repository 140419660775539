import { Col, ConfigProvider, Row, Table } from "antd";
import "antd/dist/antd.compact.css";
import de_DE from "antd/lib/locale/de_DE";
import React from "react";
import "./App.scss";
import { Filter } from "./components/Filter";
import { getIncidence, getLegend, IncidenceObject, IncidenceObjectElement, LegendObject } from "./Data";
import { locations } from "./Data/Locations";

type Props = {};
type States = {
  loading: boolean;
  data: DataElement[];
  filter: { query?: string; showDone?: boolean };
};
type DataElement = {
  key: string;
  city: string;
  ags: string;
  name: string;
  done: boolean;
  district: IncidenceObjectElement;
  incidence: { 0: number; 1: number; percent: number };
};
class App extends React.Component<Props, States> {
  private rawData: DataElement[] | undefined;
  private legendData: LegendObject | undefined;
  private fetchData: IncidenceObject | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      filter: {},
    };
  }

  componentDidMount() {
    getIncidence().then(async (data: IncidenceObject) => {
      this.legendData = await getLegend();
      this.rawData = [];
      this.fetchData = data;
      locations.forEach((cinema) => {
        if (!cinema.ags) return;
        const district = {
          ...Object.values(data.data).find((el) => {
            if (cinema.ags.toString().slice(0, 5) === el.ags.toString()) {
              console.log(el);
            }
            return cinema.ags.toString().slice(0, 5) === el.ags.toString();
          })!,
        };
        this.rawData?.push({
          key: this.rawData.length.toString(),
          ...cinema,
          incidence: {
            0: district.history[district.history.length - 1].weekIncidence,
            1: district.history[0].weekIncidence,
            percent:
              (district.history[district.history.length - 1].weekIncidence * 100) / district.history[0].weekIncidence -
              100,
          },
          district,
        });
      });

      this.setState({ loading: false, data: this.filterData() });
    });
  }

  componentDidUpdate(_: Props, prevState: States) {
    if (prevState.filter !== this.state.filter) {
      this.setState({ data: this.filterData() });
    }
  }

  render() {
    const { data } = this.state;
    const lastUpdate = this.fetchData?.meta.lastUpdate
      ? Intl.DateTimeFormat("de-DE", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }).format(new Date(this.fetchData?.meta.lastUpdate))
      : "";

    return (
      <ConfigProvider locale={de_DE}>
        <div className="App">
          <Row>
            <Col span={12} style={{ textAlign: "left" }}>
              <h1 style={{ fontWeight: "bold" }}>{process.env.REACT_APP_TITLE}</h1>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <small style={{ color: "#aaa", fontSize: "1rem" }}>
                {Intl.DateTimeFormat(undefined, {
                  day: "numeric",
                  weekday: "long",
                  month: "long",
                  year: "numeric",
                }).format()}
              </small>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ color: "#aaa", fontSize: "0.75rem" }}>
              Daten: {this.fetchData?.meta.source}, {lastUpdate}
            </Col>
          </Row>

          <Row style={{ marginBottom: 50, marginTop: 50 }}>
            <Col span={24}>
              <Filter onChange={(filter) => this.setState({ filter })} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                pagination={false}
                bordered
                loading={!data}
                dataSource={data}
                // rowSelection={{
                //   hideSelectAll: true,
                //   selectedRowKeys: data.filter((el) => el.done).map((el) => el.key),
                // }}
                columns={[
                  {
                    title: "Standort",
                    key: "name",
                    dataIndex: "city",
                    render: (text, record) => {
                      return (
                        <>
                          {text} {record.name ? <i style={{ color: "#888" }}>{record.name}</i> : ""}
                        </>
                      );
                    },
                    sorter: (a, b) => (a.city + a.name > b.city + b.name ? 1 : -1),
                  },
                  {
                    title: "AGS",
                    key: "ags",
                    dataIndex: "ags",
                    sorter: (a, b) => (a.ags > b.ags ? 1 : -1),
                  },
                  {
                    title: "Kreis",
                    key: "disctrict",
                    dataIndex: "disctrict",
                    render: (text, record) => record.district.name,
                    sorter: (a, b) => (a.district.name > b.district.name ? 1 : -1),
                  },
                  {
                    align: "center",
                    width: 25,
                    render: (text, record) => (
                      <>
                        <span
                          style={{
                            backgroundColor: this.getLegendColor(record.incidence[0]),
                            display: "inline-block",
                            width: "1rem",
                            height: "1rem",
                          }}
                        />
                      </>
                    ),
                  },
                  {
                    title: "Inzidenz (heute)",
                    key: "incidence.0",
                    dataIndex: "incidence.0",
                    defaultSortOrder: "descend",
                    render: (text, record) => record.incidence[0].toFixed(1),
                    sorter: (a, b) => (a.incidence[0] > b.incidence[0] ? 1 : -1),
                  },
                  {
                    align: "center",
                    width: 25,
                    render: (text, record) => (
                      <>
                        <span
                          style={{
                            backgroundColor: this.getLegendColor(record.incidence[1]),
                            display: "inline-block",
                            width: "1rem",
                            height: "1rem",
                          }}
                        />
                      </>
                    ),
                  },
                  {
                    title: "Inzidenz (vor 7 Tagen)",
                    key: "incidence.1",
                    dataIndex: "incidence.1",
                    render: (text, record) => record.incidence[1].toFixed(1),
                    sorter: (a, b) => (a.incidence[1] > b.incidence[1] ? 1 : -1),
                  },
                  {
                    title: "Veränderung",
                    key: "incidence.percent",
                    dataIndex: "incidence.percent",
                    render: (text, record) => (
                      <span style={{ color: record.incidence.percent > 0 ? "red" : "green" }}>
                        {record.incidence.percent > 0 ? "+" : ""}
                        {record.incidence.percent.toFixed(1) + "%"}
                      </span>
                    ),
                    sorter: (a, b) => (a.incidence.percent > b.incidence.percent ? 1 : -1),
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      </ConfigProvider>
    );
  }

  private getLegendColor(value: number) {
    return this.legendData?.incidentRanges.find((el) => el.min < value && el.max > value)?.color || "#ccc";
  }

  private filterData() {
    const { filter } = this.state;
    const data = Object.values(this.rawData!).filter((el) => {
      if (filter?.query) {
        switch (true) {
          case el.city.toLowerCase().includes(filter.query):
          case el.name.toLowerCase().includes(filter.query):
          case el.district.name.toLowerCase().includes(filter.query):
          case el.ags.toString().includes(filter.query):
            return true;
          default:
            return false;
        }
      }
      if (!filter.showDone && el.done === true) {
        // return false;
      }

      return true;
    });

    return data;
  }
}

export default App;
