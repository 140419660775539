const API_ENDPOINT = "https://api.corona-zahlen.org";

export type IncidenceObject = {
  data: { [k: string]: IncidenceObjectElement };
  meta: { lastCheckedForUpdate: string; lastUpdate: string; source: string };
};
export type IncidenceObjectElement = {
  ags: string;
  name: string;
  history: { date: string; weekIncidence: number }[];
};
export type LegendObject = {
  incidentRanges: { min: number; max: number; color: string }[];
};

const fetchData = async <T>(path: string) =>
  fetch(`${API_ENDPOINT}${path}`).then((response) =>
    response.json().then((json: T) => {
      return json;
    })
  );

const getData = async <T>(path: string) => {
  const cache = () => {
    const ttl = 6 * 60 * 60 * 1000;
    let refresh = true;
    let json = null;
    const item = window.localStorage.getItem(path);
    if (item) {
      json = JSON.parse(item);
      refresh = Math.max(0, ttl - (new Date().getTime() - new Date(json.timestamp).getTime())) === 0;
    }

    return !refresh && json ? json.data : null;
  };

  let data: T = cache();

  if (!data) {
    data = await fetchData<T>(path);
    const cacheObject = {
      timestamp: new Date().toISOString(),
      data,
    };

    window.localStorage.setItem(path, JSON.stringify(cacheObject));
  }

  return data;
};

export const getIncidence = async () => await getData<IncidenceObject>(`/districts/history/incidence/${10}`);
export const getLegend = async () => await getData<LegendObject>(`/map/districts/legend`);
