import React from "react";
import { Input, Switch, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export type Props = {
  onChange: (filter: States) => void;
};

type States = {
  query: string;
  showDone: boolean;
};

export class Filter extends React.Component<Props, States> {
  state = {
    query: "",
    showDone: true,
  };
  render() {
    return (
      <Form wrapperCol={{ span: 14 }}>
        <Form.Item>
          <Input
            size="large"
            placeholder="Suche ..."
            prefix={<SearchOutlined />}
            onChange={(e) => this.setFilter("query", e.currentTarget.value.toLowerCase())}
          />
        </Form.Item>
        {/* <Form.Item label="Erledigte Anzeigen">
          <Switch checked={this.state.showDone} onChange={(checked) => this.setFilter("showDone", checked)} />
        </Form.Item> */}
      </Form>
    );
  }

  private setFilter(key: keyof States, value: any) {
    const { onChange } = this.props;
    this.setState({ ...this.state, [key]: value }, () => onChange && onChange(this.state));
  }
}
