export const locations = [
  { city: "Augsburg", name: "", ags: "09761000", done: true },
  { city: "Bamberg", name: "", ags: "09461000", done: true },
  { city: "Berlin", name: "Cubix", ags: "11001000", done: true },
  { city: "Berlin", name: "Hellersdorf", ags: "11010000", done: true },
  { city: "Berlin", name: "Kulturbrauerei", ags: "11003000", done: true },
  { city: "Berlin", name: "Tegel", ags: "11012000", done: true },
  { city: "Berlin", name: "Treptow", ags: "11009000", done: true },
  { city: "Berlin", name: "Wildau", ags: "12061540", done: true },
  { city: "Bonn", name: "", ags: "05314000", done: false },
  { city: "Bremen", name: "", ags: "04011000", done: true },
  { city: "Chemnitz", name: "", ags: "14511000", done: true },
  { city: "Crimmitschau", name: "", ags: "14524030", done: true },
  { city: "Dortmund", name: "", ags: "05913000", done: true },
  { city: "Düsseldorf", name: "", ags: "05111000", done: true },
  { city: "Emden", name: "", ags: "03402000", done: true },
  { city: "Erfurt", name: "", ags: "16051000", done: true },
  { city: "Erlangen", name: "", ags: "09562000", done: true },
  { city: "Frankfurt am Main", name: "Metropolis", ags: "06412000", done: true },
  { city: "Frankfurt am Main", name: "Mainzer Landstraße", ags: "06412000", done: true },
  { city: "Frankfurt (Oder)", name: "", ags: "12053000", done: true },
  { city: "Fulda", name: "", ags: "06631009", done: true },
  { city: "Garbsen", name: "", ags: "03241005", done: true },
  { city: "Greifswald", name: "", ags: "13075039", done: true },
  { city: "Hagen", name: "", ags: "05914000", done: true },
  { city: "Ingolstadt", name: "", ags: "09161000", done: true },
  { city: "Jena", name: "", ags: "16053000", done: false },
  { city: "Konstanz", name: "", ags: "08335043", done: false },
  { city: "Leipzig", name: "", ags: "14713000", done: true },
  { city: "Lübeck", name: "Filmhaus", ags: "01003000", done: true },
  { city: "Lübeck", name: "Stadthalle", ags: "01003000", done: true },
  { city: "Ludwigshafen", name: "", ags: "07314000", done: true },
  { city: "Magdeburg", name: "", ags: "15003000", done: true },
  { city: "Mainz", name: "", ags: "07315000", done: true },
  { city: "Neubrandenburg", name: "", ags: "13071107", done: false },
  { city: "Neumünster", name: "", ags: "01004000", done: true },
  { city: "Oberhausen", name: "", ags: "05119000", done: true },
  { city: "Remscheid", name: "", ags: "05120000", done: true },
  { city: "Rostock", name: "Capitol", ags: "13003000", done: true },
  { city: "Rostock", name: "CineStar", ags: "13003000", done: true },
  { city: "Saarbrücken", name: "", ags: "10041100", done: true },
  { city: "Siegen", name: "", ags: "05970040", done: true },
  { city: "Stade", name: "", ags: "03359038", done: true },
  { city: "Stralsund", name: "", ags: "13073088", done: true },
  { city: "Villingen-Schwenningen", name: "", ags: "08326074", done: true },
  { city: "Waren (Müritz)", name: "", ags: "13071156", done: true },
  { city: "Weimar", name: "", ags: "16055000", done: true },
  { city: "Wismar", name: "", ags: "13074087", done: true },
  { city: "Wolfenbüttel", name: "", ags: "03158037", done: true },
];

export default locations;
